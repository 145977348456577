@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=e65d53d9-abc4-4f02-98b3-62b50db6c025");
@font-face{
  font-family:"Futura Bold";
  src:url("../fonts/futura/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix");
  src:url("../fonts/futura/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix") format("eot"),url("../fonts/futura/04a4ca93-1306-487e-a524-e798404c93a7.woff2") format("woff2"),url("../fonts/futura/2a65cbfc-5071-4b4d-bfc2-e052bb091ee2.woff") format("woff"),url("../fonts/futura/812f781d-aa66-47a1-b6a2-7941effc1527.ttf") format("truetype"),url("../fonts/futura/2a371030-ff67-40ac-8307-082c1fccf151.svg#2a371030-ff67-40ac-8307-082c1fccf151") format("svg");
}
@font-face{
  font-family:"Futura ExtraBold";
  src:url("../fonts/futura/e516390d-3788-4c01-aa7c-b3e02513fed3.eot?#iefix");
  src:url("../fonts/futura/e516390d-3788-4c01-aa7c-b3e02513fed3.eot?#iefix") format("eot"),url("../fonts/futura/cfdf2a9f-47ad-459a-a427-912b771caf18.woff2") format("woff2"),url("../fonts/futura/71412249-2d57-471a-a4d5-981114297382.woff") format("woff"),url("../fonts/futura/8ccb8a63-e876-4780-a50f-bcc566888d2a.ttf") format("truetype"),url("../fonts/futura/91e409d1-261c-43dd-8ab7-32f46c395b1c.svg#91e409d1-261c-43dd-8ab7-32f46c395b1c") format("svg");
}
